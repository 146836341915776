<template>
  <a target="_blank" :href="trackingLink()" v-if="item.id_invoice_company == 1">
    {{ item.invoice_no }}
  </a>
  <span v-else>
    {{ item.invoice_no }}
    <v-icon
      small
      class="cursor-pointer primary--text"
      @click="downloadInvoicePdf()"
    >
      mdi-download
    </v-icon>
  </span>
</template>

<script>
import { httpClient } from "@/libs/http";
export default {
  name: "InvoiceTracking",
  props: {
    item: {
      type: Object,
      default: () => "",
    },
  },
  methods: {
    trackingLink() {
      return `${process.env.VUE_APP_MEINVOICE}/tra-cuu/?sc=${this.item.transaction_id}`;
    },
    async downloadInvoicePdf() {
      try {
        const { data } = await httpClient.post("/invoice-get-pdf", this.item);
        this.downloadPDFFromBase64(data, `${this.item.invoice_no}.pdf`);
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
  },
};
</script>

<style scoped></style>
